
  import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { HasProperyPart } from "@/mixins/has-property-part";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      ValidationProvider,
    },
  })
  export default class ProperyPartInstallationElectrical extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: ProperyInstallationElectrical;

    localValue: ProperyInstallationElectrical = {
      conform_norm: null,
      has_meter: null,
      meter: null,
    };

    resetValues() {
      this.localValue.meter = null;
      this.handleInput();
    }

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: ProperyInstallationElectrical) {
      this.localValue = newValue;
    }
  }
